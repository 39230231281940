import React from 'react';

import Card from './Card';
import { SkeletonText } from './Skeleton';

export default function MetricCard({
  iconComponent: IconComponent = () => null,
  label,
  value,
  fetching,
}) {
  return (
    <Card>
      <div className="grid grid-flow-col auto-cols-max gap-x-2.5">
        <IconComponent className="-mt-1" />

        <div>
          <div className="text-sm font-semibold text-black truncate leading-none">
            {label}
          </div>
          <div className="mt-2">
            {fetching ? (
              <SkeletonText className="h-4 w-16" />
            ) : value == null ? (
              <div className="text-gray-500 leading-none">not available</div>
            ) : (
              <div className="font-semibold leading-none">{value}</div>
            )}
          </div>
        </div>
      </div>
    </Card>
  );
}
