import React from 'react';

import clsx from 'clsx';

export default function Card({
  title,
  iconComponent: IconComponent = () => null,
  padded = true,
  children,
}) {
  return (
    <div
      className={clsx(
        'bg-white shadow rounded-lg',
        padded && 'px-4 py-5 sm:p-6'
      )}
    >
      {title && (
        <div className="flex items-center pb-4 text-sm font-semibold text-black truncate border-b">
          <IconComponent className="mr-2" />
          {title}
        </div>
      )}
      {children}
    </div>
  );
}
