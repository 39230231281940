import React from 'react';

import { DatePresetQuery } from 'queries';
import { useQuery } from 'urql';

const TIME_FRAMES = {
  LAST_30_DAYS: 'Last 30 days',
  THIS_MONTH: 'This month',
  LAST_MONTH: 'Last month',
  LIFETIME: 'Lifetime',
};

export default function useDateRangePresets() {
  const [{ data, ...result }] = useQuery({
    query: DatePresetQuery,
  });

  const dateRangePresets = React.useMemo(
    () =>
      data?.datePresets.values.map(({ name: value }) => ({
        label: TIME_FRAMES[value],
        value,
      })) || [],
    [data]
  );

  return {
    ...result,
    data: dateRangePresets,
  };
}
