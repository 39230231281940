import React from 'react';

import clsx from 'clsx';
import useDateRangePresets from 'hooks/useDateRangePresets';

import { SkeletonText } from './Skeleton';

export default function DateRangePresets({ selectedPresetValue, onChange }) {
  const { data: dateRangePresets, fetching } = useDateRangePresets();

  return (
    <div className="flex flex-wrap -mx-2 md:-mx-4">
      {fetching
        ? [...Array(4).keys()].map((idx) => (
            <div key={idx} className="px-1 md:px-3 py-1">
              <SkeletonText className="h-7 w-20" />
            </div>
          ))
        : dateRangePresets.map(({ label, value }) => (
            <div key={value} className="px-1 md:px-3 py-1">
              <button
                className={clsx(
                  'p-1 rounded leading-none text-sm',
                  value === selectedPresetValue ? 'text-gray-500' : 'text-black'
                )}
                onClick={() => onChange(value)}
              >
                <span
                  className={clsx(
                    'block py-0.5 border-b border-dashed mt-px',
                    value === selectedPresetValue
                      ? 'border-transparent'
                      : 'border-black'
                  )}
                >
                  {label}
                </span>
              </button>
            </div>
          ))}
    </div>
  );
}
