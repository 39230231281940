import React from 'react';

import { Line } from 'react-chartjs-2';

import { defaultLineDataSetOptions, defaultOptions } from '.';

export default function BarChart({
  title,
  data = [],
  labels = [],
  options = {},
  ...props
}) {
  return (
    <Line
      options={{ ...defaultOptions, ...options }}
      data={{
        labels: labels,
        datasets: [
          {
            label: title,
            data: data,
            ...defaultLineDataSetOptions,
          },
        ],
      }}
      {...props}
    />
  );
}
