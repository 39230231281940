import React from 'react';

import clsx from 'clsx';
import dayjs from 'dayjs';
import BriefcaseIcon from 'images/solid/briefcase.svg';
import ClockIcon from 'images/solid/clock.svg';
import UserIcon from 'images/solid/user.svg';
import groupBy from 'lodash/fp/groupBy';
import mapValues from 'lodash/fp/mapValues';
import pipe from 'lodash/fp/pipe';
import reduce from 'lodash/fp/reduce';
import uniqBy from 'lodash/fp/uniqBy';

import Card from 'components/Card';
import BarChart from 'components/Charts/Bar';
import LineChart from 'components/Charts/Line';
import { FormattedNumber, formatNumber } from 'components/Format';

export default function BookingActivity({
  bookingActivity = [],
  dateRangePresetValue,
  showBookingsPerEmployee,
}) {
  const [selectedMetricId, setMetricId] = React.useState();
  const Chart = selectedMetricId === 'bookings/employee' ? LineChart : BarChart;

  const bookingsTotal = React.useMemo(
    () => bookingActivity.length,
    [bookingActivity]
  );
  const bookedHoursTotal = React.useMemo(
    () => bookingActivity.reduce((prev, { hours }) => prev + hours, 0),
    [bookingActivity]
  );

  const bookingsPerEmployee = React.useMemo(() => {
    if (bookingActivity.length === 0) return 0;

    const uniqEmployeeIds = uniqBy('employeeId')(bookingActivity);
    return bookingActivity.length / uniqEmployeeIds.length;
  }, [bookingActivity]);

  const metrics = React.useMemo(
    () =>
      [
        {
          id: 'bookings',
          iconComponent: BriefcaseIcon,
          label: 'Bookings',
          value: formatNumber(bookingsTotal),
        },
        {
          id: 'hours',
          iconComponent: ClockIcon,
          label: 'Hours',
          value: formatNumber(bookedHoursTotal, {
            maximumFractionDigits: 1,
          }),
        },
        showBookingsPerEmployee && {
          id: 'bookings/employee',
          iconComponent: UserIcon,
          label: 'Bookings/employee',
          value: formatNumber(bookingsPerEmployee, {
            maximumFractionDigits: 1,
          }),
        },
      ].filter(Boolean),
    [
      bookingsTotal,
      bookedHoursTotal,
      bookingsPerEmployee,
      showBookingsPerEmployee,
    ]
  );

  const title = React.useMemo(
    () => metrics.find(({ id }) => id === selectedMetricId)?.label,
    [metrics, selectedMetricId]
  );

  React.useEffect(() => setMetricId(metrics.at(0).id), []);

  const chartData = React.useMemo(() => {
    if (!dateRangePresetValue) {
      return {
        labels: [],
        data: [],
      };
    }

    if (selectedMetricId === 'bookings') {
      const { labels, data } = createChartData({
        dateRangePresetValue: dateRangePresetValue,
        data: pipe(
          reduce((prev, curr) => {
            return {
              ...prev,
              [curr.date]: prev[curr.date] ? prev[curr.date] + 1 : 1,
            };
          }, {})
        )(bookingActivity),
      });

      return { labels, data };
    }

    if (selectedMetricId === 'hours') {
      const { labels, data } = createChartData({
        dateRangePresetValue: dateRangePresetValue,
        data: pipe(
          reduce((prev, curr) => {
            return {
              ...prev,
              [curr.date]: prev[curr.date]
                ? prev[curr.date] + curr.hours
                : curr.hours,
            };
          }, {})
        )(bookingActivity),
      });

      return { labels, data };
    }

    if (selectedMetricId === 'bookings/employee') {
      const { labels, data } = createChartData({
        dateRangePresetValue: dateRangePresetValue,
        data: pipe(
          groupBy(({ date }) => date),
          mapValues((value) => {
            const uniqEmployeeIds = uniqBy('employeeId')(value);
            const allBookingsCount = value.length;

            return allBookingsCount / uniqEmployeeIds.length;
          })
        )(bookingActivity),
      });

      return { labels, data };
    }
  }, [dateRangePresetValue, bookingActivity, selectedMetricId]);

  return (
    <Card>
      <MetricsButtonBar
        metrics={metrics}
        selectedMetricId={selectedMetricId}
        onChange={setMetricId}
      />

      <div className="min-h-[400px] mt-8">
        {chartData && (
          <Chart
            title={title}
            labels={chartData.labels}
            data={chartData.data}
          />
        )}
      </div>
    </Card>
  );
}

const MetricsButtonBar = ({ metrics = [], selectedMetricId, onChange }) => {
  if (metrics.length === 0) return null;

  const handleSelectMetric = (id) => () => onChange(id);

  return (
    <div className="grid grid-flow-col auto-cols-max gap-16 justify-center">
      {metrics.map(({ id, iconComponent: IconComponent, label, value }) => (
        <div
          key={id}
          className={clsx(
            'relative flex flex-col items-center px-10 py-6 w-56 border rounded-md font-semibold transition-all',
            selectedMetricId === id
              ? 'text-spoto shadow-sm border-gray-250'
              : 'text-black border-transparent hover:shadow-sm hover:border-gray-250 '
          )}
        >
          <div className="text-5xl text-center">
            <FormattedNumber>{value}</FormattedNumber>
          </div>
          <div className="flex items-center mt-2">
            <IconComponent className="mr-2" />
            {label}
          </div>
          <button
            onClick={handleSelectMetric(id)}
            className="cover-parent sr-only select-none"
          >
            Show {label}
          </button>
        </div>
      ))}
    </div>
  );
};

const daysInDateRange = ({
  from = dayjs().subtract(1, 'month'),
  to = dayjs(),
} = {}) => {
  const diffDays = Math.abs(from.diff(to, 'day')) + 1;

  const days = [...Array(diffDays).keys()].map((day) =>
    dayjs(from).add(day, 'day').format('YYYY-MM-DD')
  );

  return days;
};

const DATE_RANGE_PRESETS = {
  LAST_30_DAYS: daysInDateRange({ from: dayjs().subtract(30, 'day') }),
  THIS_MONTH: daysInDateRange({
    from: dayjs().startOf('month'),
    to: dayjs().endOf('month'),
  }),
  LAST_MONTH: daysInDateRange({
    from: dayjs().subtract(1, 'month').startOf('month'),
    to: dayjs().subtract(1, 'month').endOf('month'),
  }),
};

const createCustomDateRange = ({ from, to }) => {
  if (!from || !to) {
    return [];
  }

  const customDateRange = daysInDateRange({
    from: dayjs(from),
    to: dayjs(to),
  });

  if (customDateRange.length < DATE_RANGE_PRESETS['LAST_30_DAYS'].length) {
    return DATE_RANGE_PRESETS['LAST_30_DAYS'];
  }

  return customDateRange;
};

const createChartData = ({
  data: data_ = [],
  dateRangePresetValue = Object.keys(DATE_RANGE_PRESETS).at(0),
}) => {
  const labels =
    DATE_RANGE_PRESETS[dateRangePresetValue] ||
    createCustomDateRange({
      from: Object.keys(data_).sort().at(0),
      to: Object.keys(data_).sort().at(-1),
    });

  const data = labels.map((day) => data_[day] || 0);

  return {
    labels: labels,
    data: data,
  };
};
