import React from 'react';

import Card from './Card';
import EmptyStateMessage from './EmptyStateMessage';
import List from './List';
import { SkeletonText } from './Skeleton';

export default function RankingCard({
  title,
  data = [],
  fetching,
  noResultsMessage,
  ...props
}) {
  const [placeholderData] = React.useState(
    [...Array(3).keys()].map(() => ({
      label: <SkeletonText className="h-5 w-full" />,
      value: <SkeletonText className="h-5 w-12" />,
    }))
  );

  return (
    <Card {...props} title={title}>
      {!fetching && data.length === 0 ? (
        <EmptyStateMessage>{noResultsMessage}</EmptyStateMessage>
      ) : (
        <List
          className="mt-4"
          data={fetching && data.length === 0 ? placeholderData : data}
        />
      )}
    </Card>
  );
}
