export const formatNumber = (num, config = {}) => {
  const {
    locale = undefined,
    minimumFractionDigits = undefined,
    maximumFractionDigits = undefined,
  } = config;

  return Number(num).toLocaleString(locale, {
    minimumFractionDigits,
    maximumFractionDigits,
  });
};

export function FormattedNumber({ children, ...config }) {
  const formatted = formatNumber(children, config);

  return formatted;
}

export const formatCurrency = (p, config = {}) => {
  const { locale = 'en-US', currency = 'USD' } = config;

  const formatted = Number(p).toLocaleString(locale, {
    style: 'currency',
    currency: currency,
    minimumFractionDigits: 2,
  });

  return formatted;
};

export function FormattedCurrency({ children }) {
  const formatted = formatCurrency(children);

  return formatted;
}
