import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LineElement,
  LinearScale,
  PointElement,
  Title,
  Tooltip,
} from 'chart.js';

ChartJS.register(
  BarElement,
  CategoryScale,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  Title,
  Tooltip
);

export const defaultOptions = {
  responsive: true,
  maintainAspectRatio: false,
  scales: {
    y: {
      ticks: {
        precision: 0,
      },
    },

    x: {
      grid: {
        display: false,
      },
    },
  },

  plugins: {
    legend: {
      display: false,
    },
    title: {
      display: false,
    },
  },
};

export const defaultBarDataSetOptions = {
  backgroundColor: '#03CA1E',
  borderWidth: 0,
  borderRadius: 2,
};

export const defaultLineDataSetOptions = {
  backgroundColor: '#03CA1E',
  borderColor: '#03CA1E',
  lineTension: 0.25,
};
