import React from 'react';

import clsx from 'clsx';

export default function List({ data = [], className }) {
  return (
    <ul className={clsx('space-y-1', className)}>
      {data.map(({ label, value }, idx) => (
        <li className="flex text-sm space-x-2" key={idx}>
          <div className="grow">{label}</div>
          <div className="shrink-0 font-semibold">{value}</div>
        </li>
      ))}
    </ul>
  );
}
