import React from 'react';

import { Bar } from 'react-chartjs-2';

import { defaultBarDataSetOptions, defaultOptions } from '.';

export default function BarChart({
  title,
  data = [],
  labels = [],
  options = {},
  ...props
}) {
  return (
    <Bar
      options={{ ...defaultOptions, ...options }}
      data={{
        labels: labels,
        datasets: [
          {
            label: title,
            data: data,
            ...defaultBarDataSetOptions,
          },
        ],
      }}
      {...props}
    />
  );
}
